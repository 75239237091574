import React, { useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { CalenderSvg } from '../../assets/svgs/Svg'

const Index = (props) => {
  const [open, setOpen] = useState(false)


  return (
    <div className="" style={{ "marginRight": "-50px" }}>
      <InputGroup className="input-group reactdate-pic m-0">
        <InputGroup.Text className="input-group-text m-0" onClick={()=>setOpen(b => !b)}>
          <div >
            <CalenderSvg/>
          </div>
        </InputGroup.Text>
        <ReactDatePicker
          className="form-control w-75 m-0"
          selected={props.selectedDate}
          onChange={(date)=>{props?.onSelectDate(date);setOpen(false)}}
          showTimeSelect
          dateFormat="Pp"
          timeIntervals={1}
          maxDate={new Date()}
          minDate={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 2,
              1
            )
          }
          open={open}
          onInputClick={() => setOpen(b => !b)}
          onClickOutside={() => setOpen(false)}
          showDisabledMonthNavigation
        />
      </InputGroup>
    </div>
  )
}

export default Index
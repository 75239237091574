// API STATUS
export const APISTATUS = "APISTATUS";

//Forgot password
export const ACTION_POST_GENERATE_OTP_REQ = "@ACTION/POST/GENERATE/OTP/REQUEST";
export const ACTION_POST_VALIDATE_OTP_REQ = "@ACTION/POST/VALIDATE/OTP/REQUEST";
export const ACTION_POST_RESET_PASSWORD_REQ = "@ACTION/POST/RESET/PASSWORD/REQUEST";

//DEPARTMENTS
export const ACTION_GET_DEPARTMENT_DETAILS_REQ = '@ACTION/GET/DEPARTMENT/DETAILS/REQUEST';

//DashboardDepartments

export const ACTION_GET_DASHBOARD_DEPARTMENT_DETAILS_REQ = '@ACTION/GET/DASHBOARD/DEPARTMENT/DETAILS/REQUEST'
export const ACTION_POST_LOGIN_REQ = "@ACTION/POST/LOCATION/LOGIN/REQUEST";
export const ACTION_POST_DEPARTMENT_DETAILS_WITH_DATE_RANGE = "@ACTION/POST/DEPARTMENT/DETAILS/WITH/DATE/RANGE";
export const ACTION_POST_LOGOUT_REQ = "@ACTION/POST/LOCATION/LOGOUT/REQUEST";
export const ACTION_POST_GET_TODAY_REQ = '@ACTION/POST/TODAY/REQUEST';


export const ACTION_POST_STUDENTATTAINDENCE_REQ = "@ACTION/POST/LOCATION/STUDENTATTAINDENCE/REQUEST";
export const ACTION_POST_STAFFATTAINDENCE_REQ = "@ACTION/POST/LOCATION/STAFFATTAINDENCE/REQUEST";

export const ACTION_POST_CHANGEPASSWORD_REQ = "@ACTION/POST/LOCATION/CHANGEPASSWORD/REQUEST";
export const ACTION_POST_SAVEHOMGE_REQ = "@ACTION/POST/LOCATION/SAVEHOMGE/REQUEST";


export const ACTION_POST_LISTOFDISTRICT_REQ = "@ACTION/POST/LOCATION/LISTOFDISTRICT/REQUEST";
export const ACTION_POST_LISTOFMANDAL_REQ = "@ACTION/POST/LOCATION/LISTOFMANDAL/REQUEST";
export const ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ = "@ACTION/POST/STUDENT/STAFF/ATTEDANCE/REQUEST";
export const ACTOIN_POST_COLLEGE_DETAILS_REQ = "@ACTION/POST/COLLEGE/DETAILS/REQUEST";

//detailed reports
export const ACTOIN_POST_STAFF_DETAILS_REQ = "@ACTION/POST/STUDENT/STAFF/DETAILS/REQUEST";
export const ACTOIN_POST_STUDENT_DETAILS_REQ = "@ACTION/POST/STUDENT/STUDENT/DETAILS/REQUEST";
export const ACTOIN_POST_SCWELFARE_DETAILS_REQ = "@ACTION/POST/STATE/SCWELFARE/DETAILS/REQUEST";
export const ACTOIN_POST_MONTHLY_DROPDOWN_REQ = "@ACTION/POST/STATE/MONTHLY/DROPDOWN/REQUEST";
export const ACTOIN_POST_MONTHLY_DETAILS_REQ = "@ACTION/POST/MONTHLY/DETAILS/REQUEST";
export const ACTOIN_POST_MONTHLY_PRINCIPAL_DETAILS_REQ = "@ACTION/POST/MONTHLY/PRINCIPAL/DETAILS/REQUEST";
export const ACTOIN_POST_MONTHLY_REPORTS_DOWNLOADS_REQ = "@ACTION/POST/MONTHLY/REPORT/DOWNLOADS/REQUEST";
export const ACTION_POST_ATTENDANCE_REPORTS_DETAILS_REQ = "@ACTION/POST/ATTENDANCE/REPORT/DETAILS/REQUEST";
export const ACTION_POST_ATTENDANCE_REPORTS_SEARCH_REQ = "@ACTION/POST/ATTENDANCE/REPORT/SEARCH/REQUEST";
export const ACTION_POST_DWM_REPORTS_REQ = "@ACTION/POST/DWM/REPORT/REQUEST";
export const ACTION_POST_ALLPUNCHES_REPORTS_REQ = "@ACTION/POST/ALLPUNCHES/REPORT/REQUEST";
export const ACTION_POST_FILO_PUNCHES_REPORTS_REQ = "@ACTION/POST/FILO/LPUNCHES/REPORT/REQUEST";
export const ACTION_POST_LATE_ARRIVAL_REPORTS_REQ = "@ACTION/POST/LATE/ARRIVAL/REPORT/REQUEST";
export const ACTION_POST_LEAVE_DETAILS_REPORTS_REQ = "@ACTION/POST/LEAVE/DETAILS/REPORT/REQUEST";
export const ACTION_POST_CONTIUOUS_ABSENT_REPORTS_REQ = "@ACTION/POST/CONTINUOUS/ABSENT/REPORT/REQUEST";
export const ACTION_POST_PROGRESS_REPORTS_REQ = "@ACTION/POST/PROGRESS/REPORT/REQUEST";

//principal 
export const ACTOIN_POST_PRINCIPAL_HOMEPAGE_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/HOMEPAGE/DETAILS/REQUEST";
export const ACTOIN_POST_PRINCIPAL_DEDUPLICATE_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/STUDENT/STAFF/DETAILS/REQUEST";
export const ACTOIN_POST_PRINCIPAL_DEDUPLICATE_HISTORY_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/STUDENT/STAFF/HISTORY/DETAILS/REQUEST";
export const ACTOIN_POST_PRINCIPAL_DEDUPLICATE_ACCEPT_REJECT_DETAILS_REQ = "@ACTION/POST/STATE/PRINCIPAL/STUDENT/STAFF/ACCEPT/REJECT/DETAILS/REQUEST";


//Grivences
export const ACTION_POST_GRIVENCE_PIECHART_REQ = "@ACTION/POST/GRIVENCE/PIECHART/REQUEST";
export const ACTION_POST_GRIVENCE_ALL_REQ = "@ACTION/POST/ALL/GRIVENCE/REQUEST";
export const ACTION_POST_GRIVENCE_TICKET_HISTORY_REQ = "@ACTION/POST/GRIVENCE/TICKET/HISTORY/REQUEST";
export const ACTION_POST_GRIVENCE_TICKET_ASSIGN_DROPDOWN_REQ = "@ACTION/POST/GRIVENCE/TICKET/LIST/DROPDOWN/REQUEST";
export const ACTION_POST_GRIVENCE_TICKET_ASSIGN_REQ = "@ACTION/POST/GRIVENCE/TICKET/ASSIGN/REQUEST";
export const ACTION_POST_GRIVENCE_INPROGRESS_REQ = "@ACTION/POST/GRIVENCE/TICKET/INPROGRESS/REQUEST";
export const ACTION_POST_GRIVENCE_PENDING_REQ = "@ACTION/POST/GRIVENCE/TICKET/PENDING/REQUEST";
export const ACTION_POST_GRIVENCE_CLOSED_ASSIGN_REQ = "@ACTION/POST/GRIVENCE/TICKET/CLOSED/REQUEST";
export const ACTION_POST_GRIVENCE_ASSIGN_TO_ME_REQ = "@ACTION/POST/GRIVENCE/TICKET/TOME/REQUEST";
export const ACTION_POST_GRIVENCE_ASSIGN_PRINCIPAL_REQ = "@ACTION/POST/GRIVENCE/TICKET/PRINCIPAL/REQUEST";

//Attendee Mangement
export const ACTION_POST_ATTENDEE_MANGEMENT_DISTRICT_LIST_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/DISTRICT/LIST/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_MANDAL_LIST_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/MANDAL/LIST/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ADD_ORG_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ADD/ORG/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_DEPARTMENT_COLLEGES_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/DEPARTMENT/COLLEGES/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ADD_CLASS_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ADD/CLASS/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ADD_STAFF_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ADD/STAFF/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STAFF_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/UPDATE/STAFF/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_COLLEGE_CLASS_LIST_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/COLLEGE/CLASS/LIST/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ADD_STUDENT_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ADD/STUDENT/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STUDENT_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/UPDATE/STUDENT/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ADD_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ADD/REQUEST";
//Take it from monthly report
// export const ACTION_GET_ATTENDEE_MANGEMENT_DEPARTMENTS_REQ = "@ACTION/GET/ATTENDEE/MANGEMENT/DEPARTMENTS/REQUEST";

export const ACTION_POST_ATTENDEE_MANGEMENT_DESIGNATION_MASTER_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/DESIGNATION/MASTER/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STUDENT_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/SINGLE/STUDENT/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STAFF_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/SINGLE/STAFF/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ADD_UPDATE_DESIGNATION_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ADD/UPDATE/DESIGNATION/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_GEO_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/UPDATE/GEO/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_STUDENT_LIST_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/STUDENT/LIST/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_STAFF_LIST_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/STAFF/LIST/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_ATTENDEE_STATUS_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/UPDATE/STATUS/REQUEST";
export const ACTION_POST_ATTENDEE_MANGEMENT_ROLE_LIST_REQ = "@ACTION/POST/ATTENDEE/MANGEMENT/ROLE/LIST/REQUEST";



// holiday principal
export const ACTION_POST_HOLIDAY_LIST_REQ = "@ACTION/POST/HOLIDAY/lIST/REQUEST";
export const ACTION_POST_HOLIDAY_ADD_REQ = "@ACTION/POST/HOLIDAY/ADD/REQUEST";
export const ACTION_POST_HOLIDAY_UPADATE_REQ = "@ACTION/POST/HOLIDAY/UPADATE/REQUEST";
export const ACTION_POST_HOLIDAY_DELETE_REQ = "@ACTION/POST/HOLIDAY/DELETE/REQUEST";

//holiday state
export const ACTION_POST_STATE_HOLIDAY_LIST_REQ = "@ACTION/POST/STATE/HOLIDAY/lIST/REQUEST";
export const ACTION_POST_STATE_HOLIDAY_ADD_REQ = "@ACTION/POST/STATE/HOLIDAY/ADD/REQUEST";
export const ACTION_POST_STATE_HOLIDAY_UPADATE_REQ = "@ACTION/POST/STATE/HOLIDAY/UPADATE/REQUEST";
export const ACTION_POST_STATE_HOLIDAY_DELETE_REQ = "@ACTION/POST/STATE/HOLIDAY/DELETE/REQUEST";

//attendance report
export const ACTION_POST_ATTENDANCE_VIEW_REPORTS_REQ = "@ACTION/POST/ATTENDANCE/VIEW/REPORT/REQUEST";
export const ACTION_POST_LEAVE_SUMMARY_REPORTS_REQ = "@ACTION/POST/LEAVE/SUMMARY/REPORT/REQUEST";
export const ACTION_POST_ATTENDANCE_VERIFICATION_REPORTS_REQ = "@ACTION/POST/ATTENDANCE/VERIFICATION/REPORT/REQUEST";
export const ACTION_POST_HIERARCHYWISE_STAFF_LIST_REQ = "@ACTION/POST/HIERARCHYWISE/STAFF/LIST/REQUEST";
export const ACTION_POST_GROUPATTENDANCE_LIST_REQ = "@ACTION/POST/GROUPATTENDANCE/LIST/REQUEST";
export const ACTION_POST_CLASS_ATTENDANCE_IMAGE_REQ = "@ACTION/POST/CLASS/ATTENDANCE/IMAGE/REQUEST";
export const ACTION_POST_STAFF_CLASS_ATTENDANCE_LIST_REQ = "@ACTION/POST/STAFF/CLASS/ATTENDANCE/LIST/REQUEST";
export const ACTION_POST_STAFF_MANUAL_ATTENDANCE_SAVE_REQ = "@ACTION/POST/STAFF/MANUAL/ATTENDANCE/SAVE/REQUEST";
//notification management
export const ACTION_POST_NOTIFICATION_LIST_REQ = "@ACTION/POST/NOTIFICATION/LIST/REQUEST";
export const ACTION_POST_NOTIFICATION_ADD_REQ = "@ACTION/POST/NOTIFICATION/ADD/REQUEST";
export const ACTION_POST_NOTIFICATION_UPDATE_REQ = "@ACTION/POST/NOTIFICATION/UPDATE/REQUEST";
export const ACTION_POST_NOTIFICATION_DELETE_REQ = "@ACTION/POST/NOTIFICATION/DELETE/REQUEST";
export const ACTION_POST_WHATSAPP_NOTIFICATION_REQ = "@ACTION/POST/WHATSAPP/NOTIFICATION/REQUEST";
export const ACTION_POST_SMS_TEMPLATE_NOTIFICATION_REQ = "@ACTION/POST/SMS/TEMPLATE/NOTIFICATION/REQUEST";
export const ACTION_POST_SEND_SMS_REQ = "@ACTION/POST/SEND/SMS/REQUEST";
export const ACTION_POST_NOTIFICATION_LOGS_REQ = "@ACTION/POST/NOTIFICATION/LOGS/REQUEST";
export const ACTION_POST_PUSH_NOTIFICATION_REQ = "@ACTION/POST/PUSH/NOTIFICATION/REQUEST";
export const ACTION_POST_SEND_WHATSAPP_NOTIFICATION_REQ = "@ACTION/POST/SEND/WHATSAPP/NOTIFICATION/REQUEST";

//auditlogs
export const ACTION_POST_AUDITLOGS_USER_REQ = "@ACTION/POST/AUDITLOGS/USER/REQUEST";
export const ACTION_POST_AUDITLOGS_LIST_REQ = "@ACTION/POST/AUDITLOGS/LIST/REQUEST";
export const ACTION_SIDE_MENU = "@ACTION/SIDE/MENU";
export const ACTION_AUTHKEY = "@ACTION/AUTHKEY";

//notification admin
export const ACTION_POST_ADMIN_PUSH_NOTIFICATION_REQ = "@ACTION/POST/ADMIN/PUSH/NOTIFICATION/REQUEST";
export const ACTION_POST_ADMIN_NOTIFICATION_LIST_REQ = "@ACTION/POST/ADMIN/NOTIFICATION/LIST/REQUEST";
export const ACTION_POST_ADMIN_NOTIFICATION_READ_LIST_REQ = "@ACTION/POST/ADMIN/NOTIFICATION/READ/LIST/REQUEST";

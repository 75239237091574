import {
  ACTION_POST_LISTOFDISTRICT_REQ,
  ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,
  ACTOIN_POST_STAFF_DETAILS_REQ,
  ACTOIN_POST_STUDENT_DETAILS_REQ,
  ACTOIN_POST_SCWELFARE_DETAILS_REQ,
  ACTOIN_POST_MONTHLY_DROPDOWN_REQ,
  ACTOIN_POST_MONTHLY_DETAILS_REQ,
  ACTOIN_POST_MONTHLY_PRINCIPAL_DETAILS_REQ,
  ACTOIN_POST_MONTHLY_REPORTS_DOWNLOADS_REQ,
  ACTION_POST_ATTENDANCE_REPORTS_DETAILS_REQ,
  ACTION_POST_DWM_REPORTS_REQ,
  ACTION_POST_ATTENDANCE_REPORTS_SEARCH_REQ,
  ACTION_POST_ALLPUNCHES_REPORTS_REQ,
  ACTION_POST_FILO_PUNCHES_REPORTS_REQ,
  ACTION_POST_LATE_ARRIVAL_REPORTS_REQ,
  ACTION_POST_LEAVE_DETAILS_REPORTS_REQ,
  ACTION_POST_CONTIUOUS_ABSENT_REPORTS_REQ,
  ACTION_POST_ATTENDANCE_VIEW_REPORTS_REQ,
  ACTION_POST_PROGRESS_REPORTS_REQ,
  ACTION_POST_LEAVE_SUMMARY_REPORTS_REQ,
  ACTION_POST_ATTENDANCE_VERIFICATION_REPORTS_REQ,
  ACTION_POST_CLASS_ATTENDANCE_IMAGE_REQ,
  ACTION_POST_STAFF_CLASS_ATTENDANCE_LIST_REQ,
  ACTION_POST_HIERARCHYWISE_STAFF_LIST_REQ,
  ACTION_POST_GROUPATTENDANCE_LIST_REQ,
  ACTION_POST_STAFF_MANUAL_ATTENDANCE_SAVE_REQ
} from "./SagaActionTypes";

export const listOfDistrictMandalsSagaAction = (payload) => ({
  type: ACTION_POST_LISTOFDISTRICT_REQ,
  payload,
});

export const getStudentStaffAttendanceSagaAction = (payload) => ({
  type: ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,
  payload,
});

export const saveStaffManualAttendanceSagaAction = (payload) => ({
  type: ACTION_POST_STAFF_MANUAL_ATTENDANCE_SAVE_REQ,
  payload,
});

export const staffdetailsAction = (payload) => ({
  type: ACTOIN_POST_STAFF_DETAILS_REQ,
  payload,
});
export const studentDetailsAction = (payload) => ({
  type: ACTOIN_POST_STUDENT_DETAILS_REQ,
  payload,
});

export const scWelfareDetailsAction = (payload) => ({
  type: ACTOIN_POST_SCWELFARE_DETAILS_REQ,
  payload,
});

export const monthlyDropdownDetailsAction = (payload) => ({
  type: ACTOIN_POST_MONTHLY_DROPDOWN_REQ,
  payload,
});

export const monthlyDetailsAction = (payload) => ({
  type: ACTOIN_POST_MONTHLY_DETAILS_REQ,
  payload,
});

export const monthlyDetailsprincipalAction = (payload) => ({
  type: ACTOIN_POST_MONTHLY_PRINCIPAL_DETAILS_REQ,
  payload,
});

export const monthlyreportdownload = (payload) => ({
  type: ACTOIN_POST_MONTHLY_REPORTS_DOWNLOADS_REQ,
  payload,
});


export const attendanceReportAction = (payload) => ({
  type: ACTION_POST_ATTENDANCE_REPORTS_DETAILS_REQ,
  payload,
});

export const attendancesearchAction = (payload) => ({
  type: ACTION_POST_ATTENDANCE_REPORTS_SEARCH_REQ,
  payload,
});

export const dwmReportAction = (payload) => ({
  type: ACTION_POST_DWM_REPORTS_REQ,
  payload,
});

export const allPunchesReportAction = (payload) => ({
  type: ACTION_POST_ALLPUNCHES_REPORTS_REQ,
  payload,
});
export const filoPunchesReportAction = (payload) => ({
  type: ACTION_POST_FILO_PUNCHES_REPORTS_REQ,
  payload,
});
export const lateArrivalReportAction = (payload) => ({
  type: ACTION_POST_LATE_ARRIVAL_REPORTS_REQ,
  payload,
});
export const leaveDetailsReportAction = (payload) => ({
  type: ACTION_POST_LEAVE_DETAILS_REPORTS_REQ,
  payload,
});
export const continuousAbsentReportAction = (payload) => ({
  type: ACTION_POST_CONTIUOUS_ABSENT_REPORTS_REQ,
  payload,
});

export const attendanceViewAction = (payload) => ({
  type: ACTION_POST_ATTENDANCE_VIEW_REPORTS_REQ,
  payload,
});
export const groupAttendanceListAction = (payload) => ({
  type: ACTION_POST_GROUPATTENDANCE_LIST_REQ,
  payload,
});

export const progressReportAction = (payload) => ({
  type: ACTION_POST_PROGRESS_REPORTS_REQ,
  payload,
});
export const leaveSummaryReportAction = (payload) => ({
  type: ACTION_POST_LEAVE_SUMMARY_REPORTS_REQ,
  payload,
});
export const attendanceVerificationReportAction = (payload) => ({
  type: ACTION_POST_ATTENDANCE_VERIFICATION_REPORTS_REQ,
  payload,
});
export const classAttendanceImageAction = (payload) => ({
  type: ACTION_POST_CLASS_ATTENDANCE_IMAGE_REQ,
  payload,
});

export const staffClassAttendanceReportAction = (payload) => ({
  type: ACTION_POST_STAFF_CLASS_ATTENDANCE_LIST_REQ,
  payload,
});
export const hierarchywiseStaffListAction = (payload) => ({
  type: ACTION_POST_HIERARCHYWISE_STAFF_LIST_REQ,
  payload,
});


